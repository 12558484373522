<template>
  <CRow>
    <CCol md="6">
      <ValidationObserver ref="form">
        <form @submit.prevent="onSubmit">
          <CCard>
            <CCardBody>
              <CRow>
                <CCol sm="12">
                  <ValidationProvider rules="required" v-slot="{ errors }" :name="$t('CHANGE_PASSWORD_PAGE_FIELD_NAME_VALIDATE_CURRENT_PASSWORD')">
                    <CInput :label="$t('CHANGE_PASSWORD_PAGE_LABEL_CURRENT_PASSWORD')" type="password" v-model="currPass" />
                    <span class="error-text" v-show="errors[0]">{{ errors[0] }}</span>
                  </ValidationProvider>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="12">
                  <ValidationProvider
                    :rules="passwordRules"
                    v-slot="{ errors }"
                    :name="$t('CHANGE_PASSWORD_PAGE_FIELD_NAME_VALIDATE_PASSWORD')"
                    vid="password"
                  >
                    <CInput :label="$t('CHANGE_PASSWORD_PAGE_LABEL_PASSWORD')" type="password" v-model="newPass" />
                    <span class="error-text" v-show="errors[0]">{{ errors[0] }}</span>
                  </ValidationProvider>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="12">
                  <ValidationProvider
                    rules="required|confirmed:password"
                    v-slot="{ errors }"
                    :name="$t('CHANGE_PASSWORD_PAGE_FIELD_NAME_VALIDATE_CONFIRM_PASSWORD')"
                  >
                    <CInput :label="$t('CHANGE_PASSWORD_PAGE_LABEL_CONFIRM_PASSWORD')" type="password" v-model="confirmPass" />
                    <span class="error-text" v-show="errors[0]">{{ errors[0] }}</span>
                  </ValidationProvider>
                </CCol>
              </CRow>
              <CRow class="btn-update mt-3">
                <CCol col="6" xl class="mb-3 mb-xl-0">
                  <CButton
                    pressed
                    block
                    color="primary"
                    aria-pressed="true"
                    class="button-update"
                    @click="changePassword"
                  >{{$t("CHANGE_PASSWORD_PAGE_BUTTON_UPDATE")}}</CButton>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </form>
      </ValidationObserver>
    </CCol>
  </CRow>
</template>

<script>
import endpoints from "@/constants/endpoints";
import responseHelper from '@/utils/response-helper';
export default {
  data() {
    return {
      currPass: "",
      newPass: "",
      confirmPass: "",
      passwordRules: {
        required: true,
        min: 6,
        max: 30,
        regex: /^[a-zA-Z0-9!@#$%^&*()-_=+]{6,30}$/
      }
    };
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }
        this.changePassword();
      });
    },
    async changePassword() {
      const data = {
        password: this.currPass,
        new_password: this.newPass
      };

      try {
        let res = await this.$http.post(endpoints.changePassword, data, {
          headers: {
            "Content-Type": "application/json"
          }
        });

        this.$notify({
          group: "notifications",
          type: "success",
          title: this.$t("CHANGE_PASSWORD_PAGE_TITLE_NOTIFY_SUCCESS"),
          text: this.$t("CHANGE_PASSWORD_PAGE_TEXT_NOTIFY_SUCCESS"),
          duration: 1000
        });
      } catch (err) {
        this.$notify({
          group: "notifications",
          type: "error",
          title: this.$t("CHANGE_PASSWORD_PAGE_TITLE_NOTIFY_ERROR"),
          text: responseHelper.getErrorMessage(err),
          duration: 3000
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.card{
  .card-body{
    .form-group{
      margin: 8px 0 8px;
    }
  }
}
.btn-update {
  width: 150px;
  float: right;
  .btn-primary{
    min-width: 120px;
    height: 36px;
    border-radius: 4px;
    background-color: #3b4b7e !important;
  }
  .btn-block{
    width: auto;
  }
}
.error-text {
  color: red;
}
</style>
